.app-container {
  background-color: rgb(39, 92, 74);
  min-height: 100vh;
  width: 100%;
}

.posts-container {
  width: 50%;
  height: 100%;
  margin: auto;
}

.single-post {
  background-color: rgb(240, 235, 227);
  width: 100%;
  /* height: 500px; */
  padding-bottom: 15px;
  border-radius: 20px;
}

.post-top-spacer {
  height: 50px;
}

.post-header {
  width: 100%;
  height: 80px;
  background-color: #8fc5b2;
  border-radius: 20px 20px 0 0;
  padding-top: 10px;
}

.post-title {
  font-size: 38px;
  font-family: 'Roboto', sans-serif;
  margin: 15px;
}

.post-author {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  margin: 15px;
  font-weight: lighter;
  font-style: italic;
}

.post-content {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  margin: 15px;
}